
/* Reverse code the user's answer depending on the question */

const Reverse = (responseValue, questionNum) => {

    let reverseQuestions = [1, 2, 5, 6, 9, 10, 11, 13, 14, 15, 17, 19]

    if (reverseQuestions.includes(questionNum)) {

        /* Reverse value = (Highest scale value + 1) - value */
        return 6 - responseValue
    } 
    
    return responseValue

}

export default Reverse