import React from "react"
import { Link } from "gatsby"
import App from "../components/App.js"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {

  return (

  <Layout>
    <SEO title="Home" />
      <App />
    
  </Layout>
)
  }

export default IndexPage
