import React, { useState } from "react"
import Question from "./question"
import Answer from "./answer"

import styles from './item.module.css'

import ProgressBar from 'react-bootstrap/ProgressBar'
import Back from "./back.js"

import {TransitionGroup, CSSTransition} from 'react-transition-group'

/* The question and items, just what is neccesary. Used on start page. */

const QuestionAnswer = props => {

    let questionNum = props.questionNum;
    let display = props.display
    let progressNum = ((questionNum) / 20) * 100;

    // const [display, setDisplay]= useState(false)

    let progressBack;
    let displayDirections;

    if (questionNum == 0) {
        progressBack = false;
        displayDirections = false;
    } else {
        progressBack = true;
        displayDirections = true;
    }

    //Add gender later when you are ready to display 3 response options
    let responseOptions;
    if (questionNum <= 19) {
        responseOptions = ['Strongly Disagree', 'Moderately Disagree', "Neither Agree nor Disagree", "Moderately Agree", "Strongly Agree"]
    } //else if (questionNum == 20) {
    //     responseOptions = ['Male', 'Female', "Other"]
     else if (questionNum == 20) {
        responseOptions = ['13-19', '20-29', "30-39", "40-49", "50+"]
    }

    //Include animations: work in progress
    if (questionNum == 0) {
        display = true;
    } else {
        display = false;
    }

    return (

        <CSSTransition in = {display} classNames= "item" timeout={1000}>

            
            <div className = {styles.container}>
                
                {progressBack && <ProgressBar className = {styles.progress} now={progressNum} />}

                <Question qNum = {questionNum} />

                <Answer response = {props.handleResponse} responseOption = {responseOptions[0]} />

                <Answer response = {props.handleResponse} responseOption = {responseOptions[1]} />

                <Answer  response = {props.handleResponse} responseOption = {responseOptions[2]} />

                <Answer response = {props.handleResponse} responseOption = {responseOptions[3]} />

                <Answer response = {props.handleResponse} responseOption = {responseOptions[4]} /> 

                {progressBack && <Back  handle = {props.handleBack}/>}

            </div>
        </CSSTransition>

    )
    
}

export default QuestionAnswer
