import React, { useState, useEffect, useLayoutEffect } from "react"
import styles from './results.module.css'

import GetResults from './getResults.js'
import Score from "./score.js"
import Spinner from 'react-bootstrap/Spinner'
import $ from "jquery";

/* Composes the result section; allows you to move around parts if users indicate they'd prefer an alternative arrangement of sections */
const UseResult = props => {

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(true);

    //lambda api call; ADD TRY CATCH

    async function fetchData() {

        let ip
        await $.getJSON('https://json.geoiplookup.io/api?callback=?', function(data) {
            ip = JSON.stringify(data, null, 2);
        });

        const results = await GetResults(props.survey, ip);
        setResult(results);

        setLoading(false);
    } 

    useEffect(() => {

        fetchData();

     }, []);

    

    if (loading) {
    return (
        <div className = 'd-flex justify-content-center' style = {{
            padding: '20rem 0rem'
        }}>

        <Spinner animation="border" role="status" variant="light">
            <span className="sr-only">Loading...</span>
        </Spinner>

        </div>
         )
    } 

    console.log(JSON.parse(result["$response"]['data']['Payload']))

    let {SABASPercentile, SABASRanking, ConPercentile, ConRanking, EmoPercentile, EmoRanking} = JSON.parse(result["$response"]['data']['Payload'])

    console.log(SABASRanking)

    //let headingOne = "Smartphone Addiction"
    let headingTwo = "Conscientiousness"
    let headingThree = "Emotional Stability"

    //let smartDefinition = "Compulsive phone use despite negative consequences."
    let conDefinition = "A person's tendency to work hard and stay organized."
    let emoDefinition = "The volatility and intensity of a person's negative emotions."

    window.scrollTo(0, 0);

    return (


        <div className = {styles.resultsContainer}>

            <h1 className = {styles.reportHeading}>Digital Wellness Report</h1>

            {/* Section One */}

            <Score definition = {conDefinition} heading = {headingTwo} ranking = {ConRanking} score = {ConPercentile} style = {'scoreTwo'}/>

            <p className = {`${styles.conBody} ${styles.bodyFormat}`}>
                <strong>The self-control muscle:</strong> Conscientiousness people have the discipline and determination to repel smartphone addiction. For instance, consider someone you know, maybe from work, who is especially productive. They likely spend an above average amount of <strong>time completing tasks</strong> with their phone put away, perhaps on air-plane mode. Sure, you might see them on their device, but it’s probably for reasons related to work. On the flip side, think of a person who is less on top of things. They might find themselves sucked into their phone like it’s a vortex — especially to play games. Why? Because less conscientious people struggle with <strong>impulse control</strong>; this makes them more prone to not just smartphone addiction, but addiction generally.
            </p>
            <hr className = {styles.hrTwo}></hr>

            {/* Section Two */}

            <Score definition = {emoDefinition} heading = {headingThree} ranking = {EmoRanking} score = {EmoPercentile} style = {'scoreThree'}  />

            <p className = {`${styles.emoBody} ${styles.bodyFormat}`}>
            <strong>Feelings affects how we use our phones:</strong> Emotional stability is crucial for wellness, both physical and mental. Less stable people tend to cling to their device to <strong>pacify negative emotions</strong>. For instance, imagine someone lying awake in bed feeling self-conscious about the interactions they had that day, a very common experience. At that moment, do you think they’re going to reach for their phone and start scrolling through a newsfeed? There’s a good chance. Conversely, a person who is more at ease might resist the urge to check their phone, they’d rather get a good night's sleep. These examples illustrate the often <strong>overlooked role</strong> emotions play in shaping our digital wellness.
            </p>
        </div>
    )
}

export default UseResult