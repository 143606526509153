import React, { useState } from "react"

import Intro from "./survey/intro.js"
import Item from './survey/item.js'
import Reverse from "./survey/Reverse.js"

import UseResult from "./results/result.js"

/* Needed for React Bootstrap to work */
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {

    /* The parent component which renders either the start, survey, or results pages */

    let [questionNum, setNum] = useState(0);

    let [CON1, setQ0] = useState();
    let [EMO1, setQ1] = useState(); //R
    let [CON2, setQ2] = useState(); //R
    let [EMO2, setQ3] = useState();
    let [CON3, setQ4] = useState();
    let [EMO3, setQ5] = useState(); //R
    let [CON4, setQ6] = useState(); //R
    let [EMO4, setQ7] = useState();
    let [CON5, setQ8] = useState();
    let [EMO5, setQ9] = useState(); //R

    let [CON6, setQ10] = useState(); //R
    let [EMO6, setQ11] = useState(); //R
    let [CON7, setQ12] = useState();
    let [EMO7, setQ13] = useState(); //R
    let [CON8, setQ14] = useState(); //R
    let [EMO8, setQ15] = useState(); //R
    let [CON9, setQ16] = useState();
    let [EMO9, setQ17] = useState(); //R
    let [CON10, setQ18] = useState();
    let [EMO10, setQ19] = useState(); //R

    //let [gender, setQ20] = useState();
    let [age, setQ20] = useState();

    const [display, setDisplay]= useState(false)

    let setters = [setQ0, setQ1, setQ2, setQ3, setQ4, setQ5, setQ6, setQ7, setQ8, setQ9, setQ10, setQ11, setQ12, setQ13, setQ14, setQ15, setQ16, setQ17, setQ18, setQ19, setQ20]

    /* set the right item value based on the current question */
    function SetItem(responseValue){

        if (questionNum <= 19) {
            responseValue = Reverse(responseValue, questionNum)
        }

        /* question num - 1 equals the array value for the setter */
        let setter = setters[questionNum]
        
        setter(responseValue)

        if (questionNum == 0) {
            setDisplay(true)
        }

        setNum(questionNum + 1)
    }
    
    /* Allow the user to go back and change their answer; abides by UX principle to be forgiving */
    const handleBack = function() {
        setNum(questionNum - 1)
    } 

    let appSection;
    let subHeading = `Find out how your digital wellness stacks up in less than five minutes.`

    // Possible directions: To start, identify the extent to which the statement below accurately or inaccurately describes you.

    if (questionNum == 0) {

        return (
            <div>
                <Intro heading = {'Unpluggie'} subHeading = {subHeading} />
                <Item handleResponse = {SetItem} questionNum = {questionNum}/>
            </div>
        )

    }  else if (questionNum <= 20) {

        if (questionNum == 1) {
            window.scrollTo(0, 0);
        }
        appSection = <Item display = {display} handleResponse = {SetItem} questionNum = {questionNum} handleBack = {handleBack}/>

    } else {
        
        let survey = {
            "conscientiousness": [CON1, CON2, CON3, CON4, CON4, CON5, CON6, CON7, CON8, CON9, CON10],
            "Emotional_Stability": [EMO1 ,EMO2 ,EMO3 ,EMO4, EMO5, EMO6, EMO7, EMO8, EMO9, EMO10],
            "Demographics": {
                "Age": age,
                "Gender": 'to be added'
            }
        };
        
        appSection = <UseResult survey = {survey} />
    }

    return (
        <div>
            {appSection}
        </div>
    )
    
}

export default App