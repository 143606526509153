import React from "react"
import styles from './score.module.css'
import Badge from 'react-bootstrap/Badge'


/* Composes the result section; allows you to move around parts if users indicate they'd prefer an alternative arrangement of sections */
const ScoreCircle = props => {

    return (
        <div className = {styles.scoreCircle}>
            <p className = {styles.scoreNumber} > 
                {props.score} / 100
            </p>
            <Badge className = "p-2 w-75" variant="success">{props.rank}</Badge> 
        </div>
    )
}

export default ScoreCircle