import React from "react"
import Button from 'react-bootstrap/Button'
import styles from './item.module.css'

const Back = props => {

    return (

        <button className = {styles.back} onMouseDown = { e => e.preventDefault()}  onClick = {props.handle}>
        Back
        </button>

        // <Button variant = 'secondary' className = {styles.back} onClick = {props.handle}>
        //     Back
        // </Button>

    )
}

export default Back