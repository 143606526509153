import AWS from 'aws-sdk'
import IDENTITY_POOL_ID from '../../../env.js'

/* Render an audio player so the user can hear rather than labouriously read your tips */


const GetResults = (survey, ip) => {

    AWS.config.region = 'us-west-2'; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IDENTITY_POOL_ID
    });

    let payload = {
        'date': new Date(),
        'survey': survey,
        'ip': JSON.parse(ip)
    }

    console.log("payload", payload)
    
    var lambda = new AWS.Lambda({region: 'us-west-2', apiVersion: '2015-03-31'});
    var pullParams = {
       FunctionName : 'unpluggie',
       Payload: JSON.stringify(payload),
       InvocationType : 'RequestResponse',
       LogType : 'None'
    };

    return lambda.invoke(pullParams).promise()

}

export default GetResults
