import React from "react"

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import Question from '../../images/question-mark-1750942.svg'
import ScoreCircle from './scoreCircle.js'

import styles from './results.module.css'

/* Displays the user's wellness rating, a definition pop-up, and ranking badge*/

const Score = props => {

    let imgStyles = {
      minWidth: 20,
      height: 25,
      cursor: 'pointer',
      backgroundSize: 'contain',
      margin: 5,
      position: 'relative',
      bottom: 2
  }

  return (

          <div className = {`${styles[props.style]} ${styles.scoreLayout}`}>
            <div className = 'd-flex align-items-center mb-3'>
              <h4 className = {styles.scoreHeading}>{props.heading}</h4>
                <OverlayTrigger placement= {'bottom'} overlay={
                  <Tooltip id= {`tooltip-top`}>
                      {props.definition}
                  </Tooltip>
                }>
                  <img style = {{...imgStyles}} src = {Question}></img>

                </OverlayTrigger>
            </div>

            <ScoreCircle rank = {props.ranking} score = {props.score} />
          </div>
            



    )
}

export default Score