import React from "react"
import styles from './item.module.css'

const wellnessQuestions = [
    'I am always prepared.',
    'I get stressed out easily.',
    'I leave my belongings around.',
    'I am relaxed most the time.',
    'I pay attention to details.',
    'I worry about things.',
    'I make a mess of things.',
    'I seldom feel blue.',
    'I get chores done right away.',
    'I am easily disturbed.',
    'I often forget to put things back in their proper place.',
    'I get upset easily.',
    'I like order.',
    'I change my mood a lot.',
    'I shirk my duties.',
    'I have frequent mood swings.',
    'I follow a schedule.',
    'I get irritated easily.',
    'I am exacting in my work.',
    'I often feel blue.',
    'What is your age?'
]


const Question = props => {

    let question = wellnessQuestions[props.qNum]

    return (
        <div style = {{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            /* marginBottom: '2rem', */
            border: '1px solid #707070',
            backgroundColor: 'white',
            color: 'black',
            height: 100,
            borderRadius: 5
        }} className = {styles.question}>
            {question}
        </div>
    )
}

export default Question

// const miniQuestions = [
//     'My smartphone is the most important thing in my life.',
//     'Conflicts have arisen between me and my family (or friends) because of my smartphone use.',
//     'Preoccupying myself with my smartphone is a way of changing my mood (I get a buzz, or I can escape or get away, if I need to).',
//     'Over time, I fiddle around more and more with my smartphone.',
//     'If I cannot use or access my smartphone when I feel like, I feel sad, moody, or irritable.',
//     'If I try to cut the time I use my smartphone, I manage to do so for a while, but then I end up using it as much or more than before.',
//     'I get chores done right away.',
//     'I have frequent mood swings.',
//     'I often forget to put things back in their proper place.',
//     'I am relaxed most of the time.',
//     'I like order.',
//     'I get upset easily.',
//     'I make a mess of things.',
//     'I seldom feel blue.'
// ]

//I attempt to use my device less then end up using it at least as much as before.