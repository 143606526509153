import React from "react"
import Button from 'react-bootstrap/Button'
import styles from './item.module.css'

/* Displays scale response options as buttons */

const Answer = props => {

    const buttonStyles = {
        minWidth: '130px',
        color: 'white',
        minHeight: 60
    }

    let responseValue;
    let responseOption = props.responseOption

    switch (responseOption) {
        case "Strongly Disagree":
            responseValue = 1
            break
        case "Moderately Disagree":
            responseValue = 2
            break
        case "Neither Agree nor Disagree":
            responseValue = 3
            break
        case "Moderately Agree":
            responseValue = 4
            break
        case "Strongly Agree":
            responseValue = 5
            break
        default:
            responseValue = responseOption
    }

    return (

        <button style = {{...buttonStyles}} className = {styles.mobileButton} onMouseDown = { e => e.preventDefault()}  onClick = {() => props.response(responseValue)}>
            {responseOption}
        </button>
        // <Button variant = 'success'  style = {{...buttonStyles}} onMouseDown = { e => e.preventDefault()}  onClick = {() => props.response(responseValue)}>
        //     {responseOption}
        // </Button>

    )
}

export default Answer