import React from "react"
import PropTypes from 'prop-types';
import styles from './intro.module.css'

/* Start page: users learn the apps name, frame, and starts the survey */

const Intro = props => {

    return (
          <div className = {styles.container}>
            <div className = {styles.copy}>

              <h1 className = {styles.heading}>{props.heading}</h1>
              <p className = {styles.bodyCopy}>
                {props.subHeading}

              </p>
            </div>
          </div>
    )
}

export default Intro

